import React from 'react';

import * as ROUTES from './Routes';
import CSVManagement from '../components/CSVManagement/CSVManagement';
import CustomerMap from '../components/CustomerMap/CustomerMap';
import DataManagement from '../components/DataManagement/DataManagement';
import NCRAssetList from '../components/NCR/NCRAssetList/NCRAssetList';
import NCRAssets from '../components/NCR/NCRAssets/NCRAssets';
import NCRClientDashboard from '../components/NCR/NCRClientDashboard/NCRClientDashboard';
import NCRClientHome from '../components/NCR/NCRClientHome/NCRClientHome';
import NCRContracts from '../components/NCR/NCRContracts/NCRContracts';
import NCRCustomers from '../components/NCR/NCRCustomers/NCRCustomers';
import NCRHome from '../components/NCR/NCRHome/NCRHome';
import NCRUserProfileWrapper from '../components/NCR/NCRUserProfile/NCRUserProfileWrapper';
import SingleAssetNCR from '../components/NCR/SingleAsset/SingleAsset';
import SingleContract from '../components/NCR/SingleContract/SingleContract';
import ClientAssets from '../components/NCRClient/ClientAssets/ClientAssets';
import SingleAssetClient from '../components/NCRClient/ClientAssets/SingleAssetClient';
import ClientContracts from '../components/NCRClient/ClientContracts/ClientContracts';
import ClientHome from '../components/NCRClient/ClientHome/ClientHome';
import ClientSingleSite from '../components/NCRClient/ClientSingleSite/ClientSingleSite';
import ClientSites from '../components/NCRClient/ClientSites/ClientSites';
import ClientUserProfileWrapper from '../components/NCRClient/ClientUserProfile/ClientUserProfileWrapper';
import SingleClientContract from '../components/NCRClient/SingleClientContract/SingleClientContract';

export const routeType = {
  ncr: 'ncr-admin',
  ncrClient: 'ncr-client',
  client: 'Client',
};

type Routes = {
  path: string;
  element: JSX.Element;
  routeType: string;
};

export const NCRRoutes: Routes[] = [
  {
    path: ROUTES.DASHBOARD_NCR,
    element: <NCRHome />,
    routeType: routeType.ncr,
  },
  {
    path: ROUTES.CLIENT_PROFILE,
    element: <NCRClientDashboard />,
    routeType: routeType.client,
  },
  {
    path: ROUTES.CLIENT_OVERVIEW,
    element: <NCRClientHome />,
    routeType: routeType.client,
  },
  {
    path: ROUTES.CLIENT_PROFILE_ASSETS,
    element: <ClientAssets />,
    routeType: routeType.client,
  },
  {
    path: ROUTES.CLIENT_PROFILE_ASSETS_SINGLE,
    element: <SingleAssetNCR />,
    routeType: routeType.client,
  },
  {
    path: ROUTES.CLIENT_PROFILE_CONTRACTS,
    element: <ClientContracts />,
    routeType: routeType.client,
  },
  {
    path: ROUTES.CLIENT_PROFILE_CONTRACTS_SINGLE,
    element: <SingleClientContract />,
    routeType: routeType.client,
  },
  {
    path: ROUTES.CLIENT_SITES,
    element: <ClientSites />,
    routeType: routeType.client,
  },
  {
    path: ROUTES.CLIENT_SINGLE_SITE,
    element: <ClientSingleSite />,
    routeType: routeType.client,
  },
  {
    path: ROUTES.CUSTOMERS,
    element: <NCRCustomers />,
    routeType: routeType.ncr,
  },
  {
    path: ROUTES.ASSETS,
    element: <NCRAssets />,
    routeType: routeType.ncr,
  },
  {
    path: ROUTES.ASSET_LIST,
    element: <NCRAssetList />,
    routeType: routeType.ncr,
  },
  {
    path: ROUTES.CONTRACTS,
    element: <NCRContracts />,
    routeType: routeType.ncr,
  },
  {
    path: ROUTES.SINGLE_CONTRACT,
    element: <SingleContract />,
    routeType: routeType.ncr,
  },
  {
    path: ROUTES.ASSETS_SINGLE,
    element: <SingleAssetNCR />,
    routeType: routeType.ncr,
  },
  {
    path: ROUTES.USER_PROFILE,
    element: <NCRUserProfileWrapper />,
    routeType: routeType.ncr,
  },
  {
    path: ROUTES.USER_PROFILE_DATA_MANAGEMENT,
    element: <DataManagement />,
    routeType: routeType.ncr,
  },
  {
    path: ROUTES.CUSTOMER_MAP,
    element: <CustomerMap />,
    routeType: routeType.ncr,
  },
];

export const NCRClientRoutes: Routes[] = [
  {
    path: ROUTES.ASSETS_SINGLE,
    element: <SingleAssetNCR />,
    routeType: routeType.ncrClient,
  },
  {
    path: ROUTES.DASHBOARD_NCR_CLIENT,
    element: <ClientHome />,
    routeType: routeType.ncrClient,
  },
  {
    path: ROUTES.CLIENT_ASSETS,
    element: <ClientAssets />,
    routeType: routeType.ncrClient,
  },
  {
    path: ROUTES.CLIENT_CONTRACTS,
    element: <ClientContracts />,
    routeType: routeType.ncrClient,
  },
  {
    path: ROUTES.CLIENT_CONTRACTS_SINGLE,
    element: <SingleClientContract />,
    routeType: routeType.ncrClient,
  },
  {
    path: ROUTES.SINGLE_CLIENT_ASSETS,
    element: <SingleAssetClient />,
    routeType: routeType.ncrClient,
  },
  {
    path: ROUTES.CLIENT_USER_PROFILE,
    element: <ClientUserProfileWrapper />,
    routeType: routeType.ncrClient,
  },
  {
    path: ROUTES.SITES,
    element: <ClientSites />,
    routeType: routeType.ncrClient,
  },
  {
    path: ROUTES.SINGLE_SITE,
    element: <ClientSingleSite />,
    routeType: routeType.ncrClient,
  },
];
