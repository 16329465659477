import React, { useCallback, useEffect, useState } from 'react';

import { CloseOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import {
  Col,
  Button,
  Input,
  Table,
  Empty,
  Card,
  Form,
  Modal,
  Select,
  message,
  Alert,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { CSVLink } from 'react-csv';

import { CustomerRole } from '../../../auth/AuthProvider';
import { AssetType } from '../../../models/AssetType';
import parseCSV from '../../../utils/mockData/customerAssets';
import './NCRAssetList.scss';
import { mockAssets } from '../../../utils/mockData/mockAssets';
import ClearFilter from '../../ClearFilter';

const AssetListCardTitle = (customerName: string) => {
  return (
    <div className="flex justify-between items-center">
      <h2>Asset List</h2>
      <h2>{customerName}</h2>
    </div>
  );
};

const NCRAssetList = () => {
  interface SearchCriteria {
    column:
      | 'address'
      | 'city'
      | 'state'
      | 'serialNbr'
      | 'services'
      | 'site-name'
      | 'createdAt'
      | 'productId';
    value: string;
  }

  const { Search } = Input;
  const { Option } = Select;

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchField, setSearchField] = useState('Select Field');
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria | null>(
    null
  );
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchError, setSearchError] = useState<boolean>(false);
  const [initialCustomerData, setInitialCustomerData] = useState<AssetType[]>(
    []
  );
  const [filteredData, setFilteredData] = useState<AssetType[]>([]);
  const [updatedServiceTag, setUpdatedServiceTag] = useState('');
  const [updatedSiteName, setUpdatedSiteName] = useState('');
  const [editingAsset, setEditingAsset] = useState<{ id: string } | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const user = sessionStorage.getItem('user');
  const parsedUser = JSON.parse(user!);

  const getCustomerName = (preferredUsername: string) => {
    switch (preferredUsername) {
      case CustomerRole.cadenceUser:
        return 'Cadence';
      case CustomerRole.valleyNationalBankUser:
        return 'Valley National Bank';
      case CustomerRole.gecuUser:
        return 'GECU';
      case CustomerRole.dollarbankUser:
        return 'DOLLAR BANK';
      case CustomerRole.eslUser:
        return 'ESL Federal Credit Union';
      case CustomerRole.eastwestbankUser:
        return 'East West Bank';
      case CustomerRole.plainscapitalUser:
        return 'PLAINSCAPITAL BANK';
      case CustomerRole.santanderUser:
        return 'SANTANDER BANK';
      case CustomerRole.deltaUser:
        return 'DELTA COMMUNITY CREDIT UNION';
      case CustomerRole.becuUser:
        return 'BECU';
      case CustomerRole.arvestUser:
        return 'ARVEST BANK OPERATIONS INC.';
      case CustomerRole.empeopleUser:
        return 'Empeople Credit Union';
      case CustomerRole.southcarolinaUser:
        return 'SOUTH CAROLINA FEDERAL CREDIT UNION';
      default:
        return 'NCR Atleos User';
    }
  };

  useEffect(() => {
    if (parsedUser.preferred_username) {
      const fetchData = async () => {
        try {
          const data = await parseCSV(parsedUser.preferred_username);
          setInitialCustomerData(data);
          setFilteredData(data);
        } catch (error) {
          console.error('Error fetching and parsing CSV:', error);
          throw error;
        }
      };

      fetchData();
    } else {
      setInitialCustomerData(mockAssets);
      setFilteredData(mockAssets);
    }
  }, []);

  const handleSearch = (value: string) => {
    if (searchCriteria === null && value !== '') {
      setSearchError(true);
      return;
    }
    setSearchError(false);

    if (searchCriteria !== null && value) {
      const filtered = initialCustomerData.filter((asset) => {
        if (searchCriteria) {
          switch (searchCriteria.column) {
            case 'address':
              return asset.addressLine1
                ?.toLowerCase()
                .includes(value.toLowerCase());
            case 'city':
              return asset.city?.toLowerCase().includes(value.toLowerCase());
            case 'state':
              return asset.state?.toLowerCase().includes(value.toLowerCase());
            case 'serialNbr':
              return asset.serialNbr
                ?.toLowerCase()
                .includes(value.toLowerCase());
            case 'services':
              return asset.services[0].id
                ?.toLowerCase()
                .includes(value.toLowerCase());
            case 'site-name':
              return asset.site?.name
                ?.toLowerCase()
                .includes(value.toLowerCase());
            case 'createdAt':
              return asset.createdAt
                ?.toLowerCase()
                .includes(value.toLowerCase());
            case 'productId':
              return asset.productId
                ?.toLowerCase()
                .includes(value.toLowerCase());
            default:
              return false;
          }
        }
        return false;
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(initialCustomerData);
    }
  };

  const clearFilters = () => {
    setSearchField('Select Field');
    setSearchValue('');
    setSearchCriteria(null);
    setFilteredData(initialCustomerData);
  };

  const handlePageChange = (pagination: any) => {
    setPage(pagination.current - 1);
    setPageSize(pagination.pageSize);
  };

  const edit = (record: AssetType) => {
    setEditingAsset({ id: record.id });
  };

  const serviceTagNotUpdated = useCallback(
    (record: AssetType) => {
      return record.services[0].id === updatedServiceTag;
    },
    [updatedServiceTag]
  );

  const siteNameNotUpdated = useCallback(
    (record: AssetType) => {
      return record.site?.name === updatedSiteName;
    },
    [updatedSiteName]
  );

  const handleSave = (record: AssetType) => {
    try {
      if (updatedServiceTag !== '') {
        const matchingSiteTag = filteredData.find(
          (asset) => asset.id === record.id
        );
        if (matchingSiteTag) {
          matchingSiteTag.services[0].id = updatedServiceTag;
        }
      }

      if (updatedSiteName !== '') {
        const matchingAsset = filteredData.find(
          (asset) => asset.id === editingAsset?.id
        );

        if (matchingAsset) {
          const matchingSiteIds = filteredData.filter(
            (asset) => asset.site?.id === matchingAsset.site?.id
          );
          if (matchingSiteIds.length > 0) {
            setIsModalVisible(true);

            matchingSiteIds.map((asset) => {
              if (asset.site && asset.site?.name) {
                asset.site.name = updatedSiteName;
              }
            });
          }
        }
      }
      setEditingAsset(null);
      setUpdatedServiceTag('');
      setUpdatedSiteName('');
      setIsModalVisible(false);
    } catch (error) {
      message.error('An error occurred while saving. Please try again.');
    }
  };

  const handleCancel = () => {
    setEditingAsset(null);
    setIsModalVisible(false);
    setUpdatedServiceTag('');
    setUpdatedSiteName('');
  };

  const headers = [
    'Address',
    'City',
    'State',
    'Site Name',
    'Serial Number',
    'Service Tag',
    'PID / Model',
    'Install Date',
  ];

  const csvData = filteredData.map((asset) => [
    asset.addressLine1,
    asset.city,
    asset.state,
    asset.site?.name,
    asset.serialNbr,
    asset.services[0].id,
    asset.productId,
    asset.createdAt,
  ]);

  const columns: ColumnsType<AssetType> = [
    {
      title: `Address`,
      key: `address`,
      dataIndex: `addressLine1`,
      sorter: (a, b) => {
        const addressA = a.addressLine1 || '';
        const addressB = b.addressLine1 || '';
        return addressA.localeCompare(addressB);
      },
    },
    {
      title: `City`,
      key: `city`,
      dataIndex: `city`,
      sorter: (a, b) => {
        const cityA = a.city || '';
        const cityB = b.city || '';
        return cityA.localeCompare(cityB);
      },
    },
    {
      title: `State`,
      key: `state`,
      dataIndex: `state`,
      sorter: (a, b) => {
        const stateA = a.state || '';
        const stateB = b.state || '';
        return stateA.localeCompare(stateB);
      },
    },
    {
      title: `Site Name`,
      key: `site`,
      dataIndex: `site`,
      render: (_, record: AssetType) =>
        editingAsset?.id === record.id ? (
          <Form.Item style={{ margin: '-5px', width: 200 }}>
            <Input
              placeholder={record.site?.name}
              defaultValue={record.site?.name}
              onChange={(event) => setUpdatedSiteName(event.target.value)}
            />
          </Form.Item>
        ) : (
          <div className="site-name" style={{ width: 200 }}>
            {record.site?.name}
          </div>
        ),
      sorter: (a, b) => {
        const siteNameA = a.site?.name || '';
        const siteNameB = b.site?.name || '';
        return siteNameA.localeCompare(siteNameB);
      },
    },
    {
      title: `Serial Number`,
      key: `serialNbr`,
      dataIndex: `serialNbr`,
      sorter: (a, b) =>
        a.serialNbr
          .split('-')
          .join('')
          .localeCompare(b.serialNbr.split('-').join('')),
    },

    {
      title: `Service Tag`,
      key: `services`,
      dataIndex: `services`,
      render: (_, record: AssetType) =>
        editingAsset?.id === record.id ? (
          <Form.Item style={{ margin: '-5px', padding: 0, width: 150 }}>
            <Input
              placeholder={record.services[0].id}
              defaultValue={record.services[0].id}
              onChange={(event) => setUpdatedServiceTag(event.target.value)}
            />
          </Form.Item>
        ) : (
          <div className="service-tag" style={{ width: 150 }}>
            {record.services[0].id}
          </div>
        ),
      sorter: (a, b) => a.services[0].id.localeCompare(b.services[0].id),
    },
    {
      title: `PID / Model`,
      key: `productId`,
      dataIndex: `productId`,
      sorter: (a, b) => a.productId.localeCompare(b.productId),
    },
    {
      title: `Install Date`,
      key: `createdAt`,
      dataIndex: `createdAt`,
      sorter: (a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt),
    },
    {
      title: `Actions`,
      key: `actions`,
      dataIndex: `actions`,
      render: (_, record: AssetType) =>
        editingAsset?.id === record.id ? (
          <div>
            {(serviceTagNotUpdated(record) && siteNameNotUpdated(record)) ||
            (!updatedServiceTag && !updatedSiteName) ||
            (serviceTagNotUpdated(record) && !updatedSiteName) ||
            (!updatedServiceTag && siteNameNotUpdated(record)) ? (
              <Button
                disabled
                icon={<SaveOutlined style={{ color: 'green' }} />}
              />
            ) : (
              <Button
                onClick={() => setIsModalVisible(true)}
                icon={<SaveOutlined style={{ color: 'green' }} />}
              />
            )}
            <Button
              icon={
                <CloseOutlined
                  style={{ color: 'red ' }}
                  onClick={handleCancel}
                />
              }
            />
          </div>
        ) : (
          <Button
            style={{ width: 65, justifyContent: 'center' }}
            onClick={() => edit(record)}
            icon={<EditOutlined />}
          />
        ),
    },
  ];

  const getModalMessage = () => {
    if (updatedServiceTag && updatedSiteName) {
      return (
        <p>
          Update the Service Tag for this asset and the Site Name for all assets
          with the same Site ID?
        </p>
      );
    }
    if (updatedServiceTag && !updatedSiteName) {
      return <p>Update the Service Tag for this asset?</p>;
    }
    if (!updatedServiceTag && updatedSiteName) {
      return <p>Update the Site Name for all assets with the same Site ID?</p>;
    }
  };

  return (
    <Col span={24}>
      <Alert
        message="This demo environment will reset all changes upon logout."
        type="warning"
        style={{ textAlign: 'center', marginBottom: '10px', fontSize: '16px' }}
      />

      <Card
        title={AssetListCardTitle(
          getCustomerName(parsedUser.preferred_username)
        )}
        className="h-full asset-list-card"
      >
        <div>
          <Search
            value={searchValue}
            enterButton="Search"
            onChange={(event) => setSearchValue(event.target.value)}
            onSearch={handleSearch}
            addonBefore={
              <Form.Item style={{ height: 6 }}>
                <Select
                  value={searchField}
                  onChange={(value) => {
                    setSearchCriteria({
                      column: value as SearchCriteria['column'],
                      value: '',
                    });
                    setSearchError(false);
                    setSearchField(value);
                  }}
                  style={{
                    width: 150,
                    border: searchError ? '2px solid red' : '',
                  }}
                  placeholder="Search Field"
                >
                  <Option value="address">Address</Option>
                  <Option value="city">City</Option>
                  <Option value="state">State</Option>
                  <Option value="site-name">Site Name</Option>
                  <Option value="serialNbr">Serial Number</Option>
                  <Option value="services">Service Tag</Option>
                  <Option value="productId">PID / Model</Option>
                  <Option value="createdAt">Install Date</Option>
                </Select>
              </Form.Item>
            }
            prefix={
              searchCriteria && <ClearFilter clearFilters={clearFilters} />
            }
          />
          {searchError && (
            <p style={{ color: 'red', margin: '5px 0 0 0' }}>
              Please select search criteria
            </p>
          )}
        </div>
        <br />
        <Table
          columns={columns}
          rowKey={(record) => record.id!}
          dataSource={filteredData}
          onChange={handlePageChange}
          locale={{
            emptyText: <Empty description="No assets" />,
          }}
          pagination={{
            defaultPageSize: 10,
            current: page + 1,
            pageSize,
            total: filteredData.length,
            pageSizeOptions: ['10', '20', '50', '100'],
            showSizeChanger: true,
            hideOnSinglePage: false,
          }}
        />
        <Button className="csv-export-button" size="middle" type="primary">
          <CSVLink
            filename={`asset-list-${new Date().toISOString()}.csv`}
            headers={headers}
            data={csvData}
          >
            Export to CSV
          </CSVLink>
        </Button>
        <Modal
          title="Confirm Update"
          open={isModalVisible}
          onOk={() =>
            handleSave(
              filteredData.find(
                (asset) => asset.id === editingAsset?.id
              ) as AssetType
            )
          }
          onCancel={handleCancel}
        >
          {getModalMessage()}
        </Modal>
        <p className="results-counter">Total: {filteredData.length} assets</p>
      </Card>
    </Col>
  );
};

export default NCRAssetList;
