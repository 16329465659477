import React from 'react';

import { Button, Col, Row, Space, notification } from 'antd';

import CustomerMapTable from './CustomerMapTable';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const CustomerMap = () => {
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description,
    });
  };

  return (
    <Row gutter={16}>
      {contextHolder}
      <Col span={24}>
        <CustomerMapTable
          openNotificationWithIcon={openNotificationWithIcon}
          searchFields={[
            { id: 'assetClass', value: 'Class (First 4 digit of Prod ID)' },
            { id: 'productId', value: 'Product ID' },
            { id: 'serviceId', value: 'Service ID' },
            { id: 'coverage', value: 'Coverage' },
            { id: 'customerNbr', value: 'Customer Number' },
            { id: 'quoteNbr', value: 'Quote Number' },
            { id: 'serviceType', value: 'Service Type' },
          ]}
        />
      </Col>
    </Row>
  );
};

export default CustomerMap;
