import React, { createContext, useMemo, useState } from 'react';

// TODO: move this to more appropriate place
export enum UserRole {
  admin = 'ncr-admin',
  client = 'ncr-client',
}

export enum CustomerRole {
  cadenceUser = 'cadence-user',
  valleyNationalBankUser = 'valleynationalbank-user',
  gecuUser = 'gecu-user',
  customer = 'ncr-customer',
  dollarbankUser = 'dollarbank-user',
  eslUser = 'esl-user',
  eastwestbankUser = 'eastwestbank-user',
  plainscapitalUser = 'plainscapital-user',
  santanderUser = 'santander-user',
  deltaUser = 'delta-user',
  becuUser = 'becu-user',
  arvestUser = 'arvest-user',
  empeopleUser = 'empeople-user',
  southcarolinaUser = 'southcarolina-user',
}

type AuthContextType = {
  auth: any;
  setAuth: (value: any) => void;
};

const AuthContext = createContext<any | null>(null);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore next-line
export const AuthProvider: React.FC = ({ children }): JSX.Element => {
  const [auth, setAuth] = useState<AuthContextType>();

  const value = useMemo(() => {
    return { auth, setAuth };
  }, [auth]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;
