import React, { useState } from 'react';

import { Button, Card, Empty, Input, Modal, Select, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useQuery } from 'react-query';

import { AuditTrailType } from './audit-trail-map';
import './AuditTrail.scss';
import AuditTrailService from '../../APIServices/AuditTrailService';
import ClearFilter from '../ClearFilter';
import { CustomerMapType } from './customer-map';

type AuditTrailModalProps = {
  setAuditTrail: React.Dispatch<React.SetStateAction<boolean>>;
  auditTrail: boolean;
  searchFields?: { id: string; value: string }[];
  auditRecord: CustomerMapType;
};

const AuditTrailModal = ({
  auditTrail,
  setAuditTrail,
  searchFields,
  auditRecord,
}: AuditTrailModalProps) => {
  const [searchField, setSearchField] = useState<string>('Select Field');
  const [searchValue, setSearchValue] = useState<string>('');
  // const [customerMapData, setCustomerMapData] = useState(customerMap);
  const { Search } = Input;
  const { Option } = Select;

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [filters, setFilters] = useState('');

  // fetch data
  const { data: auditLog, isLoading } = useQuery(
    ['audit-log-customer-map', page, pageSize, auditRecord, filters],
    () => AuditTrailService.getAuditLog(page, pageSize, auditRecord, filters)
  );

  const selectBefore = (
    <div>
      <Select
        defaultValue="Select Field"
        placeholder="Select Field"
        value={searchField}
        onChange={(e) => setSearchField(e)}
        style={{ width: 200 }}
        getPopupContainer={(trigger) => trigger.parentElement}
      >
        {searchFields?.map((item: { id: string; value: string }) => {
          return (
            <Option key={item.id} value={item.id}>
              {item.value}
            </Option>
          );
        })}
      </Select>
    </div>
  );

  const tableRefresh = () => {
    setPage(0);
    setPageSize(10);
  };

  const handleSearch = (e: any) => {
    tableRefresh();
    const encodedValue = encodeURIComponent(e.trim());
    setFilters(`${searchField}=${encodedValue}`);
  };
  const clearFilters = () => {
    tableRefresh();
    setSearchField('Select Field');
    setSearchValue('');
    setFilters('');
  };

  const columns: ColumnsType<AuditTrailType> = [
    {
      title: 'Class (First 4 digit of Prod ID)',
      dataIndex: 'assetClass',
      key: 'assetClass',
    },
    {
      title: 'Product ID',
      key: 'productId',
      render: (record: AuditTrailType) => (
        <span className=" whitespace-nowrap">{record.productId}</span>
      ),
    },
    {
      title: 'Service ID',
      key: 'serviceNbr',
      render: (record: AuditTrailType) => (
        <span className=" whitespace-nowrap">{record.serviceNbr}</span>
      ),
    },
    {
      title: 'Service Description',
      key: 'serviceDescription',
      dataIndex: 'serviceDescription',
    },
    {
      title: 'Annual Rate',
      key: 'annualRate',
      dataIndex: 'annualRate',
    },
    {
      title: 'Coverage',
      key: 'coverage',
      dataIndex: 'coverage',
    },
    {
      title: 'Customer Number',
      key: 'customerNbr',
      dataIndex: 'customerNbr',
    },
    {
      title: 'Quote Number',
      key: 'quoteNbr',
      dataIndex: 'quoteNbr',
    },
    {
      title: 'Execution Status',
      key: 'executionStatus',
      dataIndex: 'executionStatus',
    },
    {
      title: 'Uploaded By',
      key: 'uploadedBy',
      dataIndex: 'uploadedBy',
    },
    {
      title: 'Uploaded At',
      key: 'uploadedAt',
      render: (record: AuditTrailType) => (
        <span className=" whitespace-nowrap">{record.uploadedAt}</span>
      ),
    },
    {
      title: 'Execution Summary',
      key: 'executionSummary',
      dataIndex: 'executionSummary',
    },
  ];
  return (
    <Modal
      title="Audit Trail"
      open={auditTrail}
      onCancel={() => setAuditTrail(false)}
      afterClose={() => clearFilters()}
      destroyOnClose
      width="90%"
      footer={[
        <Button key="back" size="large" onClick={() => setAuditTrail(false)}>
          Close
        </Button>,
      ]}
    >
      <Card>
        <div className="search-bar">
          <Search
            addonBefore={selectBefore}
            placeholder="Search for Coverage, Quote Number, Execution Status, Uploaded By, Uploaded At"
            size="large"
            enterButton="Search"
            disabled={searchField === 'Select Field'}
            onSearch={(e) => handleSearch(e)}
            value={searchValue}
            prefix={
              searchField !== 'Select Field' && (
                <ClearFilter clearFilters={clearFilters} />
              )
            }
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <br />
        <Table
          columns={columns}
          rowKey={(record) => record.id!}
          id="audit-trail"
          loading={isLoading}
          dataSource={auditLog?.data}
          locale={{
            emptyText: <Empty description="No audit log data" />,
          }}
          className="audit-log-table"
          pagination={{
            defaultPageSize: 10,
            current: page + 1,
            pageSize,
            total: Number(auditLog?.headers['x-total-count']),
            pageSizeOptions: ['10', '20', '50', '100'],
            showSizeChanger: true,
            hideOnSinglePage:
              Number(auditLog?.headers['x-total-count']) <= pageSize,
          }}
        />
      </Card>
    </Modal>
  );
};

export default AuditTrailModal;
