import { axiosPrivate } from '../api/axios';

export default class CustomerMapService {
  static async getCustomerMap(
    page: number,
    size: number,
    isDeleted: string,
    filters?: string
  ) {
    return axiosPrivate({
      url: `/customer-map${filters ? `?${filters}` : ''}`,
      method: 'GET',
      params: {
        page,
        size,
        isdeleted: isDeleted,
      },
    });
  }
}
